import React from 'react';
import PropTypes from 'prop-types';
import './SuccessMessage.css';

class SuccessMessage extends React.Component {
    render () {
    	let numbersListItems = [];
        if (this.props.allowedNumbers.length > 0) {
            numbersListItems  = this.props.allowedNumbers.map((number, ind) =>
                <li key={`number-${ind}`}>{number}</li>
            );
        }
        return (
			<div className="success-message">
                <h3>Thank you for signing up for the 1-to-1 Primary Care Administrative Fee!</h3>
                <p>Your continued support makes it possible for us to provide the highest quality, most personalized pediatric care that you and your family deserve.</p>
                <br/>
                <p><i>You signed up with the following numbers:</i></p>
                <ul>{numbersListItems}</ul>
                <p>Calls originating from the numbers above will be routed to the nurse on staff when you call our advice line at <strong>925-575-7321</strong>.</p>
                <p>Your subscription began on {new Date(this.props.subscriptionStart).toLocaleDateString("en-US")} and ends on {new Date(this.props.subscriptionEnd).toLocaleDateString("en-US")}</p>
            </div>
        );
    }
}

SuccessMessage.propTypes = {
  allowedNumbers: PropTypes.array,
  subscriptionStart: PropTypes.number,
  subscriptionEnd: PropTypes.number
};

export default SuccessMessage;