import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner'
import Input from 'react-phone-number-input/input';
import PropTypes from 'prop-types';
import AdviceLineCheckout from './AdviceLineCheckout';
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import {
  BrowserView,
  MobileView } from "react-device-detect";
import 'react-phone-number-input/style.css';
import './FamilyInfoForm.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class FamilyInfoForm extends React.Component {
   constructor(props) {
    super(props);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitAndContinueToPayment = this.handleSubmitAndContinueToPayment.bind(this);
    this.toggleParent2 = this.toggleParent2.bind(this);
    this.addChild = this.addChild.bind(this);
    this.removeChild = this.removeChild.bind(this);

    this.state = {
      parent1: {
        firstName: '',
        lastName: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      },
      parent2: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      },
      children: [{firstName: '', lastName: '', dob: '', pcp: ''}],
      email: '', // email associated w/ parent1, but used as primary key
      additionalPhone: '',
      validated: false,
      successfullyValidated: false,
      phoneErrors: {
        parent1Phone: false,
        parent1PhoneEmpty: false,
        parent2Phone: false,
        additionalPhone: false
      },
      hasErrors: false,
      showSpinner: false,
      parent2Enabled: false
    };
  }

  handleBackButton() {
    this.setState({successfullyValidated: false});
  }

  toggleParent2(evt) {
    const parent2 = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: ''
    };
    this.setState({ parent2, parent2Enabled: evt.target.checked });
  }

  addChild() {
    const children = this.state.children.slice();
    children.push({firstName: '', lastName: '', dob: '', pcp: ''});
    this.setState({children});
  }

  removeChild() {
    if (this.state.children.length > 1) {
      const children = this.state.children.slice();
      children.pop();
      this.setState({children});
    }
  }

  handleChange(event, parentObjName, childIndex) {
    const { target } = event;
    const { value, name } = target;
    if (childIndex != null && !!parentObjName) {
      let children = this.state.children.slice();
      children[childIndex][name] = value;
      this.setState({children});
    } else if (!!parentObjName) {
      let parentObj = this.state[parentObjName];
      const targetObj = {};
      targetObj[name] = value;
      parentObj = Object.assign({}, parentObj, targetObj);
      this.setState({[parentObjName]: parentObj})
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmitAndContinueToPayment(event) {
    const form = event.currentTarget;
    event.preventDefault();
    let phoneNumbersValid = true;
    let phoneErrors = {
      parent1Phone: false,
      parent1PhoneEmpty: false,
      parent2Phone: false,
      additionalPhone: false
    };
    const { parent1, parent2, additionalPhone } = this.state;

    if (!!parent1.phone && !isPossiblePhoneNumber(parent1.phone)) {
      phoneNumbersValid = false;
      phoneErrors.parent1Phone = true;
    } else if (!parent1.phone) {
      phoneErrors.parent1PhoneEmpty = true;
    }

    if (!!parent2 && !!parent2.phone && !isPossiblePhoneNumber(parent2.phone)) {
      phoneNumbersValid = false;
      phoneErrors.parent2Phone = true;
    }
    if (!!additionalPhone && !isPossiblePhoneNumber(additionalPhone)) {
      phoneNumbersValid = false;
      phoneErrors.additionalPhone = true;
    }

    if (form.checkValidity() === false || !phoneNumbersValid) {
      event.stopPropagation();
      this.setState({validated: true, phoneErrors, hasErrors: true})
    } else {
      this.setState({ validated: true, successfullyValidated: true, phoneErrors, hasErrors: false })
    }
  }

  render () {
    const { email, parent1, parent2, additionalPhone, showSpinner, hasErrors, parent2Enabled } = this.state;
    const displayPayButton = this.state.successfullyValidated;
    const validationError = (<Alert key={"alert-1"} variant={"danger"}>One or more fields below, highlighted in red, have errors. Please verify your information and try again</Alert>);
    return (
      <React.Fragment>
      <BrowserView>
        <div className="welcome-info">
          {!displayPayButton && <p>Enter the information below for one or more parents and for every child that is part of the practice.  For each patient, please select the primary care physician (Drs. Nash or Borden).<br/><br/>Once the information has been entered, you will be directed to pay the appropriate fee for the number of children. You will be charged a prorated amount for the remainder of this year. In order to enable families to use their HSA accounts, you will automatically be charged your family's appropriate annual fee in early January of next year.<br/><br/>You will receive an email confirming the charges and information entered.<br/><br/>You will receive notifications via email and text reminding you of the upcoming charge that will automatically be posted.  If you wish to cancel, please let us know ahead of time.  Also, please notify us if your credit or HSA card information changes during the year. </p>}
        </div>
        <div className="family-info-form">
          {!displayPayButton && <h3>Family Information</h3>}
           {hasErrors && validationError}
           {!displayPayButton && 
            <Form noValidate validated={this.state.validated} className="info-form" onSubmit={this.handleSubmitAndContinueToPayment}>
              <strong>Parent 1:</strong>
              <br/>
              <Row>
                <Col>
                  <Form.Control required type="text"
                    placeholder="First name"
                    name="firstName"
                    onChange={val => this.handleChange(val, 'parent1')}
                    value={parent1.firstName} />
                </Col>
                <Col>
                  <Form.Control required type="text"
                    placeholder="Last name"
                    name="lastName"
                    onChange={val => this.handleChange(val, 'parent1')}
                    value={parent1.lastName} />
                </Col>
              </Row>
              <br/>
              <Row>
              <Col>
                  <Input
                    placeholder="Parent 1 Phone"
                    className="form-control"
                    required
                    country="US"
                    value={parent1.phone}
                    onChange={parent1Phone => this.handleChange({ target: { name: 'phone', value: parent1Phone } }, 'parent1')} />
                    {this.state.phoneErrors.parent1Phone && <small className="text-danger">Number should be of format xxx-xxx-xxxx</small>}
                    {this.state.phoneErrors.parent1PhoneEmpty && <small className="text-danger">At least one phone number is required</small>}
                </Col>
                <Col>
                  <Form.Control required type="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.handleChange} />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                    <Form.Control required type="text"
                      name="address1"
                      placeholder="Address"
                      value={parent1.address1}
                      onChange={val => this.handleChange(val, 'parent1')} />
                </Col>
                <Col>
                  <Form.Control type="text"
                    name="address2"
                    placeholder="Apartment, studio, or floor"
                    onChange={val => this.handleChange(val, 'parent1')}
                    value={parent1.address2} />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                    <Form.Control required id="city" 
                      placeholder="City"
                      name="city"
                      type="text"
                      value={parent1.city}
                      className="form-control"
                      onChange={val => this.handleChange(val, 'parent1')} />
                  </Col>
                  <Col style={{"margin-top":"10px"}}>
                    <Form.Control value={parent1.state} required style={{"fontFamily": "'Source Code Pro', monospace", "boxShadow": "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px"}} as="select" name="state" onChange={val => this.handleChange(val, 'parent1')} className="form-control">
                      <option value="">Select state...</option>
                      <option value="CA">California</option>
                      <option disabled value="">- - - - - - - - - - - -</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </Form.Control>
                  </Col>
                <Col>
                  <Form.Control required id="zip" 
                    placeholder="Zip Code (5 digit)"
                    name="zip"
                    type="text"
                    value={parent1.zip}
                    pattern="[0-9]{5}"
                    className="form-control"
                    onChange={val => this.handleChange(val, 'parent1')} />
                </Col>
              </Row>
              <br/>
              <Form.Check 
                custom
                type={'checkbox'}
                id={`parent2Checkbox`}
                name="parent2Enabled"
                checked={this.state.parent2Enabled}
                onChange={this.toggleParent2}
                label={`Add second parent`}
              />
              <br/>
              {parent2Enabled &&
              <React.Fragment>
              <strong>Parent 2:</strong>
              <br/>
              <Row>
                <Col>
                  <Form.Control required={parent2Enabled} type="text"
                    placeholder="First name"
                    name="firstName"
                    value={parent2.firstName}
                    onChange={val => this.handleChange(val, 'parent2')} />
                </Col>
                <Col>
                  <Form.Control required={parent2Enabled} type="text"
                    placeholder="Last name"
                    name="lastName"
                    value={parent2.lastName}
                    onChange={val => this.handleChange(val, 'parent2')} />
                </Col>
              </Row>
              <br/>
              <Row>
              <Col>
                  <Input
                    placeholder="Parent 2 Phone"
                    className="form-control"
                    required={parent2Enabled}
                    country="US"
                    value={parent2.phone}
                    onChange={parent2Phone => this.handleChange({ target: { name: 'phone', value: parent2Phone } }, 'parent2')} />
                    {this.state.phoneErrors.parent2Phone && <small className="text-danger">Number should be of format xxx-xxx-xxxx</small>}
                    {this.state.phoneErrors.parent2PhoneEmpty && <small className="text-danger">At least one phone number is required</small>}
                </Col>
                <Col>
                  <Form.Control type="email"
                    name="email"
                    placeholder="Email"
                    value={parent2.email}
                    required={parent2Enabled}
                    onChange={val => this.handleChange(val, 'parent2')} />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                    <Form.Control type="text"
                      name="address1"
                      placeholder="Address"
                      required={parent2Enabled}
                      value={parent2.address1}
                      onChange={val => this.handleChange(val, 'parent2')} />
                </Col>
                <Col>
                  <Form.Control type="text"
                    name="address2"
                    placeholder="Apartment, studio, or floor"
                    value={parent2.address2}
                    onChange={val => this.handleChange(val, 'parent2')} />
              </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                    <Form.Control id="city" 
                      placeholder="City"
                      name="city"
                      type="text"
                      value={parent2.city}
                      required={parent2Enabled}
                      onChange={val => this.handleChange(val, 'parent2')}
                      className="form-control" />
                  </Col>
                  <Col style={{"margin-top":"10px"}}>
                    <Form.Control value={parent2.state} required={parent2Enabled} style={{"fontFamily": "'Source Code Pro', monospace", "boxShadow": "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px"}} as="select" name="state" onChange={val => this.handleChange(val, 'parent2')} className="form-control">
                      <option value="">Select state...</option>
                      <option value="CA">California</option>
                      <option disabled value="">- - - - - - - - - - - -</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </Form.Control>
                  </Col>
                <Col>
                  <Form.Control id="zip" 
                    placeholder="Zip Code (5 digit)"
                    name="zip"
                    type="text"
                    required={parent2Enabled}
                    value={parent2.zip}
                    onChange={val => this.handleChange(val, 'parent2')}
                    pattern="[0-9]{5}"
                    className="form-control" />
                </Col>
              </Row>
              <br/>
              </React.Fragment>}
              <strong>Enter Additional Phone Number for Advice Line (Optional)</strong>
              <br/>
              <small><i>Only registered numbers will get through to the advice line.</i></small>
              <Row>
                <Col>
                  <Input
                    placeholder="Enter phone number"
                    country="US"
                    className="form-control"
                    name="additionalPhone"
                    value={additionalPhone}
                    onChange={additionalPhone => this.handleChange({ target: { name: 'additionalPhone', value: additionalPhone } })} />
                    {this.state.phoneErrors.additionalPhone && <small className="text-danger">Number should be of format xxx-xxx-xxxx</small>}
                  </Col>
              </Row>
              <br/>
              <h4>Children</h4>
              {this.state.children.map((child, index) =>
                <React.Fragment key={`child-${index}`}>
                <strong>Child {index + 1}</strong> 
                <Row>
                  <Col>
                    <Form.Control required type="text"
                      placeholder="First name"
                      name="firstName"
                      onChange={val => this.handleChange(val, 'children', index)}
                      value={child.firstName} />
                  </Col>
                  <Col>
                    <Form.Control required type="text"
                      placeholder="Last name"
                      name="lastName"
                      onChange={val => this.handleChange(val, 'children', index)}
                      value={child.lastName} />
                  </Col>
                  <Col style={{"margin-top":"10px"}}>
                    <Form.Control required value={child.pcp} style={{"fontFamily": "'Source Code Pro', monospace", "boxShadow": "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px"}} as="select" name="pcp" onChange={val => this.handleChange(val, 'children', index)} className="form-control">
                      <option value="">Select doctor...</option>
                      <option value="Borden">Dr. Borden</option>
                      <option value="Nash">Dr. Nash</option>
                    </Form.Control>
                  </Col>
                  {index > 0 && index === this.state.children.length - 1 && <button onClick={() => this.removeChild()}>Remove</button>}
                </Row>
                <br/>
                </React.Fragment>)}
                {!!this.state.children[this.state.children.length - 1].firstName && !!this.state.children[this.state.children.length - 1].lastName && !!this.state.children[this.state.children.length - 1].pcp && <React.Fragment><button onClick={() => this.addChild()}>Add Additional Child</button><br/></React.Fragment>}
              <Row className="justify-content-center continue-to-payment">
                <button className="btn btn-primary" type="submit">Continue to Payment</button>
              </Row>
              {!displayPayButton && 
                <Row className="justify-content-center payment-disclaimer">
                    <small>
                      <i>
                      For this transaction, you will be charged a prorated amount of your family's annual fee for the remainder of this year. Moving forward, you will be charged for the entirety of next year in early January for {this.props.numChildren} {this.props.numChildren > 1 ? 'children' : 'child'}.
                      </i>
                    </small>
                </Row>
              }
            </Form>}
            {displayPayButton && 
              <React.Fragment>
                <div className="justify-content-center" hidden={!showSpinner}>
                  <Spinner animation="border" variant="info" />
                </div>

                <Row className="justify-content-center" hidden={showSpinner}>
                  <AdviceLineCheckout
                    parent1={parent1}
                    parent2={parent2}
                    email={email}
                    additionalPhone={additionalPhone}
                    children={this.state.children}
                    onPayment={this.props.onPayment}
                    toggleLoading={(showLoading) => this.setState({ showSpinner: showLoading })}
                    isMobile={true}
                  />
                </Row>
                <Row hidden={showSpinner} className="back-button justify-content-center">
                  <button className="btn btn-secondary btn-sm" type="button" onClick={this.handleBackButton}>Back</button>
                </Row>
              </React.Fragment>
            }
        </div>
      </BrowserView>
      <MobileView>
        <div className="welcome-info-mobile">
          {!displayPayButton && <p>Enter the information below for one or more parents and for every child that is part of the practice.  For each patient, please select the primary care physician (Drs. Nash or Borden).<br/><br/>Once the information has been entered, you will be directed to pay the appropriate fee for the number of children. You will be charged a prorated amount for the remainder of this year. In order to enable families to use their HSA accounts, you will automatically be charged your family's appropriate annual fee in early January of next year.<br/><br/>You will receive an email confirming the charges and information entered.<br/><br/>You will receive notifications via email and text reminding you of the upcoming charge that will automatically be posted.  If you wish to cancel, please let us know ahead of time.  Also, please notify us if your credit or HSA card information changes during the year. </p>}
        </div>
         <div className="form-mobile">
           {!displayPayButton && <h5>Family Information</h5>}
           {hasErrors && validationError}
           {!displayPayButton && 
            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmitAndContinueToPayment}>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                First Name
              </Form.Label>
              <Col sm={6}>
                <Form.Control required type="text"
                  placeholder="First name"
                  name="firstName"
                  onChange={val => this.handleChange(val, 'parent1')}
                  value={parent1.firstName} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Last Name
              </Form.Label>
              <Col sm={6}>
                <Form.Control required type="text"
                  placeholder="Last name"
                  name="lastName"
                  onChange={val => this.handleChange(val, 'parent1')}
                  value={parent1.lastName} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Parent 1 Phone
              </Form.Label>
              <Col>
                  <Input
                    placeholder="Parent 1 Phone"
                    className="form-control"
                    required
                    country="US"
                    value={parent1.phone}
                    onChange={parent1Phone => this.handleChange({ target: { name: 'phone', value: parent1Phone } }, 'parent1')} />
                    {this.state.phoneErrors.parent1Phone && <small className="text-danger">Number should be of format xxx-xxx-xxxx</small>}
                    {this.state.phoneErrors.parent1PhoneEmpty && <small className="text-danger">At least one phone number is required</small>}
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Email
              </Form.Label>
              <Col sm={6}>
                <Form.Control required type="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Address 1
              </Form.Label>
              <Col sm={6}>
                <Form.Control required type="text"
                  name="address1"
                  placeholder="Address"
                  value={parent1.address1}
                  onChange={val => this.handleChange(val, 'parent1')} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Address 2
              </Form.Label>
              <Col sm={6}>
                <Form.Control type="text"
                  name="address2"
                  placeholder="Apartment, studio, or floor"
                  onChange={val => this.handleChange(val, 'parent1')}
                  value={parent1.address2} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                City
              </Form.Label>
              <Col sm={6}>
                <Form.Control required id="city" 
                    placeholder="City"
                    name="city"
                    type="text"
                    value={parent1.city}
                    className="form-control"
                    onChange={val => this.handleChange(val, 'parent1')} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  State
                </Form.Label>
                <Col sm={6}>
                  <Form.Control value={parent1.state} required style={{"fontFamily": "'Source Code Pro', monospace", "boxShadow": "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px"}} as="select" name="state" onChange={val => this.handleChange(val, 'parent1')}>
                    <option value="">Select state...</option>
                    <option value="CA">California</option>
                    <option disabled value="">- - - - - - - - - - - -</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  Zip
                </Form.Label>
                <Col sm={6}>
                  <Form.Control required id="zip" 
                    placeholder="Zip Code (5 digit)"
                    name="zip"
                    type="text"
                    value={parent1.zip}
                    pattern="[0-9]{5}"
                    className="form-control"
                    onChange={val => this.handleChange(val, 'parent1')} />
                </Col>
            </Form.Group>
            <br/>
            <Form.Check 
                custom
                type={'checkbox'}
                id={`parent2Checkbox`}
                name="parent2Enabled"
                checked={this.state.parent2Enabled}
                onChange={this.toggleParent2}
                label={`Add second parent`}
              />
            <br/>
            {parent2Enabled &&
            <React.Fragment>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                First Name
              </Form.Label>
              <Col sm={6}>
                <Form.Control required={parent2Enabled} type="text"
                  placeholder="First name"
                  name="firstName"
                  onChange={val => this.handleChange(val, 'parent2')}
                  value={parent2.firstName} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Last Name
              </Form.Label>
              <Col sm={6}>
                <Form.Control required={parent2Enabled} type="text"
                  placeholder="Last name"
                  name="lastName"
                  onChange={val => this.handleChange(val, 'parent2')}
                  value={parent2.lastName} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Parent 2 Phone
              </Form.Label>
              <Col>
                  <Input
                    placeholder="Parent 2 Phone"
                    className="form-control"
                    required={parent2Enabled}
                    country="US"
                    value={parent2.phone}
                    onChange={parent2Phone => this.handleChange({ target: { name: 'phone', value: parent2Phone } }, 'parent2')} />
                    {this.state.phoneErrors.parent2Phone && <small className="text-danger">Number should be of format xxx-xxx-xxxx</small>}
                    {this.state.phoneErrors.parent2PhoneEmpty && <small className="text-danger">At least one phone number is required</small>}
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Email
              </Form.Label>
              <Col sm={6}>
                <Form.Control required={parent2Enabled} type="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Address 1
              </Form.Label>
              <Col sm={6}>
                <Form.Control required={parent2Enabled} type="text"
                  name="address1"
                  placeholder="Address"
                  value={parent2.address1}
                  onChange={val => this.handleChange(val, 'parent2')} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Address 2
              </Form.Label>
              <Col sm={6}>
                <Form.Control type="text"
                  name="address2"
                  placeholder="Apartment, studio, or floor"
                  onChange={val => this.handleChange(val, 'parent2')}
                  value={parent2.address2} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                City
              </Form.Label>
              <Col sm={6}>
                <Form.Control required={parent2Enabled} id="city" 
                    placeholder="City"
                    name="city"
                    type="text"
                    value={parent2.city}
                    className="form-control"
                    onChange={val => this.handleChange(val, 'parent2')} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  State
                </Form.Label>
                <Col sm={6}>
                  <Form.Control value={parent2.state} required={parent2Enabled} style={{"fontFamily": "'Source Code Pro', monospace", "boxShadow": "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px"}} as="select" name="state" onChange={val => this.handleChange(val, 'parent2')}>
                    <option value="">Select state...</option>
                    <option value="CA">California</option>
                    <option disabled value="">- - - - - - - - - - - -</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  Zip
                </Form.Label>
                <Col sm={6}>
                  <Form.Control required={parent2Enabled} id="zip" 
                    placeholder="Zip Code (5 digit)"
                    name="zip"
                    type="text"
                    value={parent2.zip}
                    pattern="[0-9]{5}"
                    className="form-control"
                    onChange={val => this.handleChange(val, 'parent2')} />
                </Col>
            </Form.Group>
            <br/>
            </React.Fragment> 
            }
            <strong>Enter Additional Phone Number for Advice Line (Optional)</strong>
            <br/>
            <small><i>Only registered numbers will get through to the advice line.</i></small>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Additional Phone (Optional)
              </Form.Label>
              <Col sm={6}>
                <Input
                  placeholder="Enter phone number"
                  className="form-control"
                  country="US"
                  value={additionalPhone}
                  onChange={additionalPhone => this.handleChange({ target: { name: 'additionalPhone', value: additionalPhone } })} />
                  {this.state.phoneErrors.additionalPhone && <small className="text-danger">Number should be of format xxx-xxx-xxxx</small>}
              </Col>
            </Form.Group>
            <br/>
            {this.state.children.map((child, index) =>
                <React.Fragment key={`child-${index}`}>
                <strong>Child {index + 1}</strong> 
                <Row>
                  <Col>
                    <Form.Control required type="text"
                      placeholder="First"
                      name="firstName"
                      onChange={val => this.handleChange(val, 'children', index)}
                      value={child.firstName} />
                  </Col>
                  <Col>
                    <Form.Control required type="text"
                      placeholder="Last"
                      name="lastName"
                      onChange={val => this.handleChange(val, 'children', index)}
                      value={child.lastName} />
                  </Col>
                  <Col style={{"margin-top":"10px"}}>
                    <Form.Control required value={child.pcp} style={{"fontFamily": "'Source Code Pro', monospace", "boxShadow": "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px"}} as="select" name="pcp" onChange={val => this.handleChange(val, 'children', index)} className="form-control">
                      <option value="">Doctor...</option>
                      <option value="Borden">Dr. Borden</option>
                      <option value="Nash">Dr. Nash</option>
                    </Form.Control>
                  </Col>
                  {index > 0 && index === this.state.children.length - 1 && <button onClick={() => this.removeChild()}>Remove</button>}
                </Row>
                <br/>
                </React.Fragment>)}
            <Form.Group as={Row}>
            {!!this.state.children[this.state.children.length - 1].firstName && !!this.state.children[this.state.children.length - 1].lastName && !!this.state.children[this.state.children.length - 1].pcp && <React.Fragment><button onClick={() => this.addChild()}>Add Additional Child</button><br/></React.Fragment>}
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={6}><button className="btn btn-primary" type="submit">Continue to Payment</button></Col>
            </Form.Group>
            {!displayPayButton && 
              <Row className="justify-content-center payment-disclaimer">
                <Col sm={6}>
                  <small>
                    <i>
                      For this transaction, you will be charged a prorated amount of your family's annual fee for the remainder of this year. Moving forward, you will be charged for the entirety of next year in early January for {this.props.numChildren} {this.props.numChildren > 1 ? 'children' : 'child'}.
                    </i>
                  </small>
                </Col>
              </Row>
            }
          </Form>}
          {displayPayButton && 
            <React.Fragment>
              <div className="justify-content-center" hidden={!showSpinner}>
                <Spinner animation="border" variant="info" />
              </div>
              <Row hidden={showSpinner} className="back-button">
                <button className="btn btn-secondary btn-sm" type="button" onClick={this.handleBackButton}>Back</button>
              </Row>
              <Row className="justify-content-center" hidden={showSpinner}>
                <Col sm={6}>
                  <AdviceLineCheckout
                    parent1={parent1}
                    parent2={parent2}
                    email={email}
                    additionalPhone={additionalPhone}
                    children={this.state.children}
                    onPayment={this.props.onPayment}
                    toggleLoading={(showLoading) => this.setState({ showSpinner: showLoading })}
                    isMobile={true}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
        </div>
      </MobileView>
      </React.Fragment>
    );
  }
}

FamilyInfoForm.propTypes = {
  onPayment: PropTypes.func
};

export default FamilyInfoForm;