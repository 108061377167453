import React from 'react';
import {StripeProvider} from 'react-stripe-elements';
import FamilyInfoForm from './FamilyInfoForm';
import SuccessMessage from './SuccessMessage';
import './App.css';
import config from './config';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.handlePayment = this.handlePayment.bind(this);
        this.state = {
            allowedNumbers: [],
            successMessage: '',
            subscriptionStart: null,
            subscriptionEnd: null
        }
    }

    handlePayment(resultData) {
        this.setState({
            successMessage: resultData.message,
            allowedNumbers: resultData.allowedNumbers,
            subscriptionStart: resultData.subscription.current_period_start * 1000,
            subscriptionEnd: resultData.subscription.current_period_end * 1000 })
    }

    render () {
        return (
            <StripeProvider apiKey={config.stripe.apiKey}>
                <div className="root">
                    <div className="logo-heading">
                        <img alt="1-to-1 Pediatrics Logo" src={require('./Logo.png')}/>
                        <h2 className="page-title">1-to-1 Primary Care</h2>
                        <h4>Administrative Fee Signup</h4>
                        <hr/>
                    </div>
                    {!this.state.successMessage ? <FamilyInfoForm onPayment={this.handlePayment} /> : 
                        <SuccessMessage
                            allowedNumbers={this.state.allowedNumbers}
                            subscriptionStart={this.state.subscriptionStart}
                            subscriptionEnd={this.state.subscriptionEnd} />
                    }
                </div>
            </StripeProvider>
        );
    }
}

export default App;
