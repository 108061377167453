// CheckoutForm.js
import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import CardSection from './CardSection';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import './CheckoutForm.css';
import config from './config'

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.sendCheckoutRequest = this.sendCheckoutRequest.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { 
            paymentError: false,
            emailError: false,
            unknownError: false,
            cardKey: 0
        };
    }

    async sendCheckoutRequest(paymentMethodId) { // payment info returned from stripe
        const {
            parent1,
            parent2,
            children,
            email,
            additionalPhone
        } = this.props;

        const customerInfo = {
            parent1,
            email,
            children
        };

        if (!!parent2) {
            customerInfo.parent2 = parent2;
        }
        if (!!additionalPhone) {
            customerInfo.additionalPhone = additionalPhone;
        }

        const res = await fetch(config.stripe.apiUrl, { // Backend API url
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                paymentMethodId,
                customerInfo
            }),
        });
        return res;
    }

    async handleSubmit(ev) {
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();
        this.props.toggleLoading(true);
        // Clear all errors (for now);
        this.setState({ emailError: false, paymentError: false, unknownError: false });
        // Use Elements to get a reference to the Card Element mounted somewhere
        // in your <Elements> tree. Elements will know how to find your Card Element
        // because only one is allowed.
        // See our getElement documentation for more:
        // https://stripe.com/docs/stripe-js/reference#elements-get-element
        const cardElement = this.props.elements.getElement('card');

        // From here we can call createPaymentMethod to create a PaymentMethod
        // See stripe createPaymentMethod documentation for more:
        // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
        const {error, paymentMethod} = await this.props.stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: this.props.parent1.firstName + ' ' + this.props.parent1.lastName
            }
        });

        if (!!error) {
            this.setState({ unknownError: true, cardKey: this.state.cardKey + 1 });
            this.props.toggleLoading(false);
            return;
        }

        if (!!paymentMethod) {
            const result = await this.sendCheckoutRequest(paymentMethod.id);
            // All is well, we have 200 OK
            if (result.status === 200) {
                const data = await result.json();
                this.props.onPayment(data);
            } else if (result.status === 402) {
                // Something wrong with payment method
                this.setState({ paymentError: true, cardKey: this.state.cardKey + 1 });
                this.props.toggleLoading(false);
                console.log(await result.json())
            } else if (result.status === 409) {
                // Email must already exist
                this.setState({ emailError: true, cardKey: this.state.cardKey + 1 });
                this.props.toggleLoading(false);
            } else {
                this.setState({ unknownError: true, cardKey: this.state.cardKey + 1 });
                this.props.toggleLoading(false);
            }
        } else {
            this.setState({ unknownError: true, cardKey: this.state.cardKey + 1 });
            this.props.toggleLoading(false);
        }
    }

    render() {
        const badPayment = (<Alert variant="danger">
          <Alert.Heading>Error processing payment</Alert.Heading>
          <p>
            There was a problem processing your payment. Please verify your card information and try again. The card should be in the the name of the first parent. Thanks!
          </p>
        </Alert>);
        const emailError = (<Alert variant="danger">
          <Alert.Heading>Email already exists</Alert.Heading>
          <p>
            The email you specified already exists in our system. Please contact the office at <strong>925-362-1861</strong> if you'd like to update your information. Thanks!
          </p>
        </Alert>);
        const unknownError = (<Alert variant="danger">
          <Alert.Heading>Error</Alert.Heading>
          <p>
            An unknown error has occurred. Please try again later. If the issue persists, please contact the office at <strong>925-362-1861</strong>.
          </p>
        </Alert>);
        return (
            <div className="justify-content-center">
                {this.state.paymentError && badPayment}
                {this.state.emailError && emailError}
                {this.state.unknownError && unknownError}
                <form onSubmit={this.handleSubmit}>
                    <CardSection key={this.state.cardKey} isMobile={this.props.isMobile} numChildren={this.props.children.length}/>
                    <button>Subscribe</button>
                </form>
            </div>
        );
    }
}

CheckoutForm.propTypes = {
    parent1: PropTypes.object,
    parent2: PropTypes.object,
    children: PropTypes.array,
    email: PropTypes.string,
    additionalPhone: PropTypes.string,
    onPayment: PropTypes.func,
    toggleLoading: PropTypes.func,
    isMobile: PropTypes.bool
};

export default injectStripe(CheckoutForm);