// MyStoreCheckout.js
import React from 'react';
import {Elements} from 'react-stripe-elements';
import PropTypes from 'prop-types';
import InjectedCheckoutForm from './CheckoutForm';
import './AdviceLineCheckout.css';

class AdviceLineCheckout extends React.Component {
  render() {
    return (
      <div>
	      <Elements>
	        <InjectedCheckoutForm
	        	{...this.props}
	         />
	      </Elements>
      </div>
    );
  }
}

AdviceLineCheckout.propTypes = {
	parent1: PropTypes.object,
	parent2: PropTypes.object,
	email: PropTypes.string,
	children: PropTypes.array,
	additionalPhone: PropTypes.string,
	onPayment: PropTypes.func,
	toggleLoading: PropTypes.func,
	isMobile: PropTypes.bool
};

export default AdviceLineCheckout;