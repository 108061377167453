// CardSection.js
import React from 'react';
import {CardElement} from 'react-stripe-elements';
import { Col, Row } from 'react-bootstrap';

import './CardSection.css';
import './AnnualFees';
import {getAnnualFee, getProratedAmount} from './AnnualFees';

class CardSection extends React.Component {

  render() {
    const year = new Date().getFullYear();
    const numChildren = this.props.numChildren;
    const annualFee = getAnnualFee(numChildren, year);
    const nextAnnualFee = year < 2023 ? getAnnualFee(numChildren, 2023) : annualFee;
    const proratedAmt = getProratedAmount(annualFee);
    const createOptions = (fontSize, padding) => {
      const styles = {
          style: {
            base: {
              fontSize,
              color: '#424770',
              letterSpacing: '0.025em',
              fontFamily: 'Source Code Pro, monospace',
              '::placeholder': {
                color: '#aab7c4',
              },
              padding,
            },
            invalid: {
              color: '#9e2146',
            },
          },
        };
        return styles;
      };


    return (
      <div className={this.props.isMobile ? "mobile-card-section" : ""}>
        <label>Card details</label>
        <CardElement className={"form-row flex-column " + (this.props.isMobile ? "mobile-card-section" : "")} {...createOptions(this.props.fontSize, null)}/>
        <Row className="justify-content-center payment-disclaimer">
            <Col>
              <small>
                <i>
                    For this transaction, you will be charged a prorated amount of your family's annual fee, ${proratedAmt}, for the remainder of this year (${annualFee} annually). Moving forward, you will be charged ${nextAnnualFee} for the entirety of next year in early January for {this.props.numChildren} {this.props.numChildren > 1 ? 'children' : 'child'}.
                </i>
              </small>
            </Col>
          </Row>
      </div>
    );
  }
}

export default CardSection;