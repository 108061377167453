const AnnualFees = {
    "2022": {
        "1Child": 225,
        "2Children": 450,
        "3orMoreChildren": 550
    },
    "2023": {
        "1Child": 225,
        "2Children": 450,
        "3orMoreChildren": 550
    }
}

const getAnnualFee = (numChildren, year) => {
    const feesForYear = AnnualFees[year < 2023 ? 2022 : 2023];
    const childKey = numChildren > 2 ? '3orMoreChildren' : (numChildren === 2 ? '2Children' : '1Child');
    return feesForYear[childKey];
}

const getProratedAmount = (annualFee) => {
    const curDate = new Date();
    return (((new Date(curDate.getFullYear() + 1, 0, 5).getTime() - curDate.getTime()) / (new Date(curDate.getFullYear() + 1, 0, 5).getTime() - new Date(curDate.getFullYear(), 0, 5).getTime())) * annualFee + .01).toFixed(2);
}

export {getAnnualFee, getProratedAmount};